<template>
  <b-table
    :busy="isLoading"
    :fields="fields"
    :items="bankAccounts"
    responsive
    show-empty
  >
    <template #cell(bankAccountName)="data">
      <a v-if="$allowPermission('agent:manage.bank-account')" href="#" @click.prevent="onSelect(data.item)">
        {{ data.value }}
      </a>
      <span v-else>
        {{ data.value }}
      </span>
    </template>
    <template #cell(bankAccountNumber)="data">
      <a v-if="$allowPermission('agent:manage.bank-account')" href="#" @click.prevent="onSelect(data.item)">
        {{ data.value }}
      </a>
      <span v-else>
        {{ data.value }}
      </span>
    </template>
    <template #cell(bankCode)="data">
      <BankIcon :bank-code="data.value" />
      {{ $displayBankName(data.value) }}
    </template>
    <template #cell(minTotalDeposit)="data">
      <span v-if="data.value > 0">{{ data.value | currency }}</span>
      <span v-else>ทุกยอดฝาก</span>
    </template>
    <template #cell(isEnable)="data">
      <b-badge :variant="data.value ? 'success' : 'danger'">
        การใช้งาน: {{
          data.value ? $t(`utils.status.active_short`) : $t(`utils.status.inactive_short`)
        }}
      </b-badge>
    </template>
    <template #cell(bankUseType)="data">
      <b-badge v-if="data.value === 0" variant="primary">{{
        $t('bank_accounts.types.deposit_withdraw')
      }}</b-badge>
      <b-badge v-if="data.value === 1" variant="success">{{
        $t('bank_accounts.types.deposit')
      }}</b-badge>
      <b-badge v-if="data.value === 2" variant="danger">{{
        $t('bank_accounts.types.withdraw')
      }}</b-badge>
      <b-badge v-if="data.value === 3" variant="warning">{{
        $t('bank_accounts.types.hold')
      }}</b-badge>
    </template>
    <template #cell(balance)="data">
      {{ data.value | currency }}
    </template>
    <template #cell(id)="data">
      <b-dropdown
        v-if="$allowPermission('agent:manage.bank-account')"
        dropleft
        size="sm"
        variant="info"
      >
        <template #button-content>
          <i class="uil uil-cog"></i>
        </template>
        <b-dropdown-item @click="onSelect(data.item)">
          <i class="uil uil-edit-alt mr-2"></i>
          {{ $t('buttons.edit') }}
        </b-dropdown-item>
        <b-dropdown-item v-if="$allowPermission('agent:transfer.bank-account')" variant="info" @click="$emit('transfer', data.item)">
          <i class="uil uil-exchange mr-2"></i>
          {{ $t('buttons.transfer') }}
        </b-dropdown-item>
        <b-dropdown-item variant="danger" @click="onDelete(data.value)">
          <i class="uil uil-trash-alt mr-2"></i>
          <span>
            {{ $t('buttons.remove') }}
          </span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <template #empty="">
      <p class="text-center text-muted">{{ $t('messages.nothing_here') }}</p>
    </template>
    <template #table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </template>
  </b-table>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  components: {
    BankIcon: () => import('../commons/bank-icon.vue'),
  },
  props: {
    bankAccounts: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'bankAccountName',
          label: `${this.$t('bank_accounts.account_name')}`,
        },
        {
          key: 'bankCode',
          label: `${this.$t('bank_accounts.bank')}`,
        },
        {
          key: 'bankAccountNumber',
          label: `${this.$t('bank_accounts.account_no')}`,
        },
        {
          key: 'agent.name',
          label: `${this.$t('bank_accounts.agent_username')}`,
        },
        {
          key: 'bankUseType',
          label: `${this.$t('bank_accounts.type')}`,
        },
        {
          key: 'minTotalDeposit',
          label: 'สำหรับยอดฝาก',
        },
        {
          key: 'isEnable',
          label: `${this.$t('fields.status')}`,
          class: 'text-center',
        },
        {
          key: 'id',
          label: '',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['adminPermissions']),
  },
  watch: {
    adminPermissions() {
      if (this.$allowPermission('agent:manage.bank-account')) {
        this.fields.splice(5, 0, { key: 'balance', label: 'คงเหลือ' })
      }
    }
  },
  mounted() {
    if (this.$allowPermission('agent:manage.bank-account')) {
      this.fields.splice(5, 0, { key: 'balance', label: 'คงเหลือ' })
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('select', value)
    },
    onDelete(value) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.$emit('delete', value)
          }
        })
    },
  },
}
</script>
